export const environment = {
  production: true,

  PORTAL_URI: `https://sales.karkanja.com/`,
  API_ENDPOINT: 'https://sales-system-api.karkanja.com/',
  API_GRAPHQL: `https://sales-system-api.karkanja.com/api/graphql`,

  AUTHORITY_ENDPOINT: 'https://auth.karkanja.com',

  POST_LOGOUT_REDIRECT_URI: 'https://sales.karkanja.com/',
  REDIRECT_URI: 'https://sales.karkanja.com/auth',
  SILENT_REDIRECT_URI: 'https://sales.karkanja.com/silent',

  REAP_URI: 'https://properties.karkanja.com',

  CLIENT_ID: "crmsalesweb",
  SCOPE: "openid profile crmsalesapi.web role orchestrate.web",

  CENTRAL : `https://scenic.karkanja.com/`,
};
